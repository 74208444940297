<template>
  <div class="facebook p-10">
    <el-tabs
      v-model="activeTab"
      class="w-100"
    >
      <el-tab-pane
        name="recharge"
        label="充值"
      >
        <recharge-list-fb
          :leave="leave"
          :leave2="activeTab"
          :queryAgencyList="queryAgencyList"
          @toggleShowRecharge="(bool) => $emit('toggleShowRecharge', bool)"
        ></recharge-list-fb>
      </el-tab-pane>
      <el-tab-pane
        name="bm"
        label="绑定BM"
      >
        <bm-list-fb
          :leave="leave"
          :leave2="activeTab"
          :queryAgencyList="queryAgencyList"
        ></bm-list-fb>
      </el-tab-pane>
      <el-tab-pane
        name="pixel"
        label="绑定像素"
      >
        <pixel-list-fb
          :leave="leave"
          :leave2="activeTab"
          :queryAgencyList="queryAgencyList"
        ></pixel-list-fb>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import RechargeListFb from './fb/RechargeListFb.vue';
import BmListFb from './fb/BmListFb.vue';
import PixelListFb from './fb/PixelListFb.vue';

export default {
  components: {
    RechargeListFb,
    BmListFb,
    PixelListFb,
  },
  props: {
    leave: {
      type: String,
    },
    queryAgencyList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeTab: 'recharge',
    };
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.facebook {
}
</style>
