<template>
  <div class="bindBmFbBox">
    <el-dialog
      title="转移BM"
      width="800px"
      :visible="show"
      @close="$emit('toggleShow')"
      :close-on-click-modal="false"
    >
      <div class="bindBmFb">
        <el-container>
          <el-main>
            <el-form
              ref="form"
              :model="formData"
              :rules="rules"
              label-position="top"
            >
              <el-form-item
                label="bm ID："
                prop="bmId"
              >
                <el-input
                  placeholder="请输入bm ID"
                  v-model="formData.bmId"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="解绑bm ID："
                prop="unbindBmId"
              >
                <el-input
                  placeholder="解绑bm ID"
                  v-model="formData.unbindBmId"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="账户id"
                prop="accountList"
              >
                <div
                  v-for="(account_id, index) in formData.accountList"
                  :key="index"
                  class="dp-flex w-100 mb-10"
                >
                  <el-form-item
                    :error="account_id.error"
                    class="w-100"
                  >
                    <div class="dp-flex">
                      <el-input
                        placeholder="回车可自动添加"
                        v-model="account_id.value"
                        @keyup.enter.native="addAccountId"
                        @change="changeAccountId(index)"
                        @blur="blurAccountId(index)"
                      ></el-input>
                      <el-select
                        placeholder="输入账单简称"
                        v-model="account_id.id"
                        class="ml-10 w-100"
                        filterable
                        :loading="account_id.loadingVlToken"
                        :disabled="!account_id.value"
                      >
                        <el-option
                          :label="query.agency"
                          :value="query.id"
                          v-for="query in account_id.queryAgency"
                          :key="query.id"
                          :disabled="!query.hasConfigVlToken"
                        >
                          <div class="dp-flex justify-btw">
                            <span>{{ query.agency }}</span>
                            <span v-if="!query.hasConfigVlToken">（请配置vlToken）</span>
                          </div>
                        </el-option>
                      </el-select>
                      <el-button
                        type="primary"
                        class="ml-10"
                        @click="addAccountId"
                        >增加</el-button
                      >
                      <el-button
                        type="danger"
                        class="ml-10"
                        @click="deleteAccountId(index)"
                        v-show="formData.accountList.length > 1"
                        >删除</el-button
                      >
                    </div>
                  </el-form-item>
                </div>
              </el-form-item>
            </el-form>
          </el-main>
          <el-footer class="border-top-1 dp-flex justify-end align-item-c">
            <el-button
              type="primary"
              @click="checkData"
              :loading="loading"
              >确认</el-button
            >
            <el-button
              type="info"
              @click="$emit('toggleShow')"
              :loading="loading"
              >取消</el-button
            >
          </el-footer>
        </el-container>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { accountBindBm } from '@/api/PropertyList';
import { findAgencyByAdAccountId } from '@/api/common/account';
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    queryAgencyList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const validatorAccount = (rule, value, callback) => {
      const lastList = value.filter((item) => !!item.value);
      if (lastList.length == 0) {
        callback(new Error('至少输入一个'));
        return;
      }
      for (let i = 0; i < lastList.length; i++) {
        if (!lastList[i].id) {
          callback(new Error('请选择账单简称'));
        }
      }
      callback();
    };
    return {
      loading: false, // 绑定中
      rules: {
        bmId: [
          {
            required: true,
            message: '请输入bm ID',
            trigger: 'blur',
          },
        ],
        accountList: [
          {
            validator: validatorAccount,
            required: true,
          },
        ],
      },
      formData: {
        bmId: '',
        unbindBmId: '',
        accountList: [
          {
            value: '',
            error: '',
            queryAgency: [],
            loadingVlToken: false,
          },
        ],
      },
    };
  },
  methods: {
    // 点击确定 验证数据
    checkData() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        const accountList = this.formData.accountList
          .filter((item) => !!item.value)
          .map((item) => {
            return {
              accountId: item.value,
              agencyId: item.id,
            };
          });
        let params = {
          ...this.formData,
          accountList,
        };
        this.submitForm(params);
      });
    },
    // 调取绑定接口
    submitForm(params) {
      this.loading = true;
      accountBindBm(params)
        .then((res) => {
          if (res.code == 0) {
            this.$alert('绑定成功 （十分钟左右生效，请在系统中刷新查看）');
            this.$emit('toggleShow');
            this.$emit('refreshList');
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 增加账户
    addAccountId() {
      this.formData.accountList.push({ value: '', error: '', queryAgency: [], loadingVlToken: false });
    },
    // 删除账户
    deleteAccountId(index) {
      this.formData.accountList.splice(index, 1);
    },
    // 失去焦点判断 查询账户id对应的账单简称
    blurAccountId(index) {
      const value = this.formData.accountList[index].value;

      if (!value) {
        let obj = {
          ...this.formData.accountList[index],
          id: null,
          queryAgency: [],
          error: '',
        };
        this.$set(this.formData.accountList, index, obj);
        return;
      }
      this.findAgencyByAdAccountId(index);
    },
    // 获取账单简称
    findAgencyByAdAccountId(index) {
      let params = {
        adAccountId: this.formData.accountList[index].value,
      };
      this.$set(this.formData.accountList[index], 'loadingVlToken', true);
      findAgencyByAdAccountId(params)
        .then((res) => {
          if (res.data) {
            this.$set(this.formData.accountList[index], 'queryAgency', [{ ...res.data }]);
            if (!res.data.hasConfigVlToken) {
              this.$set(this.formData.accountList[index], 'error', '缺少Vision Line token，请去配置Vision Line token');
            }
            this.$nextTick(() => {
              this.$set(this.formData.accountList[index], 'id', res.data.id);
            });
          } else {
            this.$set(this.formData.accountList[index], 'queryAgency', this.queryAgencyList);
          }
        })
        .finally(() => {
          this.$set(this.formData.accountList[index], 'loadingVlToken', false);
        });
    },
    // 修改账户id 充值账单简称
    changeAccountId(index) {
      this.$set(this.formData.accountList[index], 'id', null);
      this.$set(this.formData.accountList[index], 'queryAgency', []);
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.$refs.form.resetFields();
        this.formData = {
          bmId: '',
          unbindBmId: '',
          accountList: [
            {
              value: '',
              error: '',
              queryAgency: [],
              loadingVlToken: false,
            },
          ],
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bindBmFb {
  width: 100%;
  border-radius: 4px;
  background: #fff;

  .formItem {
    width: 100%;
  }
  .el-form-item {
    // margin-bottom: 22px;
  }
}
.bindBmFbBox {
  ::v-deep .el-dialog__body {
    padding: 0;
  }
}
</style>
